import * as React from 'react';

interface CTAProps {
	h2: string;
	p?: string;
}

const CTA: React.FC<CTAProps> = (props: CTAProps) => {
	return (
		<section className={'py-14'}>
			<div
				className={
					'container mx-auto flex flex-col items-center px-5 text-center'
				}
			>
				<h2
					className={
						'max-w-3xl font-sans text-3xl font-bold text-pri'
					}
				>
					{props.h2}
				</h2>
				{props.p ? (
					<p className={'py-5 font-sans text-xl text-text'}>
						{props.p}
					</p>
				) : null}
				<a
					className={
						'mt-4 rounded bg-accent px-8 py-3 font-sans text-xl font-bold text-white hover:text-white hover:opacity-90 focus:opacity-90'
					}
					href={'/search/'}
				>
					APPLY NOW
				</a>
			</div>
		</section>
	);
};

export default CTA;

import * as React from 'react';

// @ts-ignore
import { HeroSearch } from 'career-page-components';
import './Hero.css';

interface HeroProps {
    imgSrc: string;
    data: any;
}

const Hero: React.FC<HeroProps> = (props: HeroProps) => {
    return (
        <section
            className={'flex flex-col justify-end bg-cover bg-center lg:min-h-[930px]'}
            style={{ backgroundImage: `url('${props.imgSrc}')` }}
        >
            <div className={'container mx-auto px-5 flex flex-row font-sans'}>
                <div className={'flex flex-col lg:w-1/2'}/>
                <div className={'flex flex-col lg:w-1/2'}>
                    <h1 className={'pt-14 font-bold font-sans text-white text-5xl'}>Where custom technology continually innovates.</h1>
                    <h2 className={'mt-5 text-white font-sans text-3xl'}>Join our team and engineer a great career today!</h2>
                    <div className={'bg-pri p-10 px-16 max-sm:px-8 mt-10 border-t-4 border-accent job-search-form'}>
                        <span className={'text-white font-sans font-bold text-4xl'}>Search Openings</span>
                        <HeroSearch
                            data={props.data}
                            options={{
                                submitButtonText: 'Find Jobs'
                            }}
                            apiKey={'AIzaSyA-LxoOLZgEAPTqfgtFcBl8ptIq5Vd_Aow'}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;

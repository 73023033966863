import React from 'react';
import Layout from '../layouts/Layout';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import Map from '../components/Map/Map';
import CTA from '../components/CTA/CTA';
import { graphql, useStaticQuery } from 'gatsby';
import IndexHeroImg from '../images/hero-hp.jpg';
import Hero from '../components/Hero/Hero';
import bgImg1 from '../images/boyd-bg-img-1.png';
import logoWhite from '../images/logo-w.svg';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Job Opportunities',
			href: '/search/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Worldwide Opportunities',
			href: 'https://www.boydcorp.com/company/about-us/career-opportunities.html',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Boyd Careers'}
			desc={'Boyd Careers'}
		>
			<Hero data={data} imgSrc={IndexHeroImg} />
			<section className={'container mx-auto px-5 py-12 text-center'}>
				<h2 className={'font-sans text-4xl font-bold text-pri'}>
					About Boyd
				</h2>
				<div className={'flex flex-col gap-10 py-8 md:flex-row'}>
					<div
						className={'flex flex-col text-left text-text md:w-1/2'}
					>
						<p>
							Innovation demands for smaller, lighter, and faster
							technologies with intuitive new features to compete
							with the need for more sustainable solutions. More
							smart functionality operating faster in tighter
							spaces with more sophisticated interfaces mean
							increased power density and more heat, electrical,
							and mechanical challenges.
						</p>
					</div>
					<div
						className={'flex flex-col text-left text-text md:w-1/2'}
					>
						<p>
							Adding further complexity, devices are used in
							unpredictable environments with harsh conditions and
							must be contaminant and waterproof, insulated
							against temperature extremes, and ruggedized. Not
							only are we determined to find solutions to these
							challenges, Boyd strives to simplify where possible
							and create the most holistic, effective, and
							sustainable solutions.
						</p>
					</div>
				</div>
			</section>

			<section
				className={
					'flex flex-col justify-center bg-cover bg-center lg:min-h-[930px]'
				}
				style={{ backgroundImage: `url('${bgImg1}')` }}
			>
				<div
					className={'container mx-auto flex flex-row px-5 font-sans py-12'}
				>
					<div className={'flex flex-col lg:w-1/2'}>
						<div className={'flex flex-col bg-pri p-12 max-w-[600px]'}>
							<img src={logoWhite} alt={'Boyd Logo'}/>
							<span className={'text-white font-sans font-bold text-3xl my-12'}>
								Boyd is the trusted global innovator of
								sustainable solutions that make our customers’
								products better, safer, faster, and more
								reliable.
							</span>
							<span>
								<p className={'font-sans text-xl text-white mb-6'}>
									As a leader in the industry we focus on our
									people, customers, technology, innovation,
									materials, and market needs of today and
									performance requirements of the future.
								</p>
								<p className={'font-sans text-xl text-white'}>
									We are always looking for ambitious minds to
									help us deliver advanced technology to our
									customers. Together we solve the challenges
									of today and empower solutions of tomorrow.
								</p>
							</span>
							<a target={'_blank'} href="/search" className={'rounded text-xl font-bold text-white border border-white p-2 px-4 mt-6 text-center w-[180px] cursor-pointer hover:text-white hover:opacity-90 focus:opacity-90'}>
								Apply Now
							</a>
						</div>
					</div>
					<div className={'flex flex-col lg:w-1/2'} />
				</div>
			</section>
			<Map data={data} />
			<JobAlerts />
			<CTA
				h2={'Ready to get started?'}
				p={
					'Boyd is growing and wants you to join our successful team today!'
				}
			/>
		</Layout>
	);
};

export default IndexPage;

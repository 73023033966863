import './Map.css';
import * as React from 'react';
// @ts-ignore
import { USMap } from 'career-page-components';

interface mapProps {
	data: any;
}

const Map: React.FC<mapProps> = (props: mapProps) => {
	const mapOptions = {
		fill: '#E0663D',
		onHoverFill: '#0070C0',
		disabledFill: '#99AFC1'
	};
	return (
		<section className={'bg-sec py-14'}>
			<div className={'map container mx-auto px-5 text-center'}>
				<h2 className={'font-sans text-4xl font-bold text-white'}>
					Our Locations
				</h2>
				<p className={'mt-6 mb-10 font-sans text-xl text-white'}>
					Click on your state below to view our current available opportunities.
					The interactive map only reflects our North America careers. For global positions, <a href="https://www.boydcorp.com/company/about-us/career-opportunities.html" className={'hover:text-pri focus:text-pri text-accent cursor-pointer underline'}>click here</a>.
				</p>
				<USMap options={mapOptions} data={props.data} />
			</div>
		</section>
	);
};

export default Map;
